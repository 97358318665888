/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import axios from 'axios';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { CREATENEWORDER } from '../mutations';
import CheckoutForm from './CheckoutForm';
import { addOrder as addOrderAction } from '../actions';

const CheckoutContainer = styled.section`
  width: ${({ mobile }) => (mobile ? '80%' : '50%')};
  margin-left: auto;
  overflow: text-wrap;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 250px);
  overflow: scroll;
  overflow-x: hidden;
`;

const PaymentTitle = styled.h2`
  place-self: center;
  color: ${(props) => props.theme.colors.blue};
`;

const SubFields = styled.h3`
  place-self: start;

  color: ${(props) => props.theme.colors.blue};
`;

const PaymentApp = styled.section`
  height: calc(100vh - 175px);
  padding-top: 30px;
`;

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPK);

function Checkout() {
  const form = useSelector((state) => state.payment.form);
  const [clientSecret, setClientSecret] = useState('');
  const usingMobile = useSelector(({ mobile }) => mobile.mobile);
  const itemSelected = useSelector(({ payment }) => payment.item);
  const userId = useSelector(({ payment }) => payment.userid);
  const dispatch = useDispatch();

  const [addOrder, { loading, data: orderData }] = useMutation(CREATENEWORDER);

  useEffect(() => {
    if (orderData?.CreateOrder?.success === true && !loading) {
      dispatch(addOrderAction(orderData.CreateOrder.order));
    }
  }, [orderData]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (form.email !== '') {
      if (!process.env.REACT_APP_EXPRESS) {
        return;
      }
      axios
        .post(
          'https://xi63prlsrc.execute-api.eu-west-2.amazonaws.com/dev/paymentintent',
          JSON.stringify({ item: itemSelected.item, email: form.email }),
        )
        .then((res) => {
          const data = JSON.parse(res.data.body);
          addOrder({
            variables: { input: { item: itemSelected.item, userId } },
          });
          setClientSecret(data.clientSecret);
        });
    }
  }, [form.email, itemSelected.item, userId]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <PaymentApp>
      <CheckoutContainer mobile={usingMobile}>
        {clientSecret && !loading ? (
          <>
            <PaymentTitle>Coxhoe Trail Run</PaymentTitle>
            <SubFields>Email: {form.email}</SubFields>
            <SubFields>OrderId: {orderData.CreateOrder.order._id}</SubFields>

            <SubFields>Item: {orderData.CreateOrder.order.item}</SubFields>
            <SubFields>Price: {orderData.CreateOrder.order.total}</SubFields>
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </>
        ) : (
          <CircularProgress style={{ placeSelf: 'center' }} />
        )}
      </CheckoutContainer>
    </PaymentApp>
  );
}
export default Checkout;
