/* eslint-disable object-curly-newline */
import { ADD_ORDER } from '../actions/actionTypes';

const initialState = {
  order: {},
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    default:
      return state;
  }
};
