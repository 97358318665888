/* eslint-disable object-curly-newline */
import { MOBILE_SET_USING_MOBILE } from '../actions/actionTypes';

const initialState = {
  mobile: false,
};

export const mobileReducer = (state = initialState, action) => {
  switch (action.type) {
    case MOBILE_SET_USING_MOBILE:
      return {
        ...state,
        mobile: true,
      };
    default:
      return state;
  }
};
