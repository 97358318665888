/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import './App.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useWindowDimension } from './hooks/useWindowDimension';
import { setUsingMobile } from './actions';
import { RootLayout } from './RootLayout';

import Homepage from './pages/Homepage';
// import AdminDashboard from './pages/AdminDashboard';
import RegistrationForm from './pages/RegistrationForm';
import PaymentConfirmation from './pages/PaymentConfirmation';

import Checkout from './pages/Checkout';
import { PrivacyPolicyPage } from './pages/PrivacyPolicy';

// import Cookies from "js-cookie";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPEPK}`);

export const urlTrackingEndpoints = [
  '/activelifecoxhoef',
  '/durhamparkrun',
  '/home',
  '/ctri',
  '/sedgefieldparkrun',
  '/sedgefieldharriers',
  '/',
  '/ctp',
  '/ctl',
];

function App() {
  const [modalOn, setModal] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const dispatch = useDispatch();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<RootLayout urlTrackingEndpoints={urlTrackingEndpoints} />}
        errorElement={
          <Homepage
            modalOn={modalOn}
            setModal={setModal}
            setUserLoggedIn={setUserLoggedIn}
            urlTrackingEndpoints={urlTrackingEndpoints}
          />
        }
      >
        {urlTrackingEndpoints.map((url) => (
          <Route
            key={url}
            path={url}
            element={
              <Homepage
                modalOn={modalOn}
                setModal={setModal}
                setUserLoggedIn={setUserLoggedIn}
                urlTrackingEndpoints={urlTrackingEndpoints}
              />
            }
          />
        ))}

        <Route path="checkout" element={<Checkout />} />

        {/* <Route path="/dashboard" element={<AdminDashboard />} /> */}

        <Route path="register" element={<RegistrationForm />} />
        <Route path="privacy" element={<PrivacyPolicyPage />} />

        <Route
          path="paymentconfirmation/:orderId"
          element={
            <Elements stripe={stripePromise}>
              <PaymentConfirmation />
            </Elements>
          }
        />
      </Route>,
    ),
  );

  // Product Id State
  const dimensions = useWindowDimension();

  useEffect(() => {
    if (dimensions.width < 450 && dimensions.height < 900) {
      dispatch(setUsingMobile({ mobile: true }));
    }
  }, [dimensions]);

  // const apiUrl = process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   const token = Cookies.get("token");
  //   fetch(`${apiUrl}/ValidateToken`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     credentials: "include",
  //     body: JSON.stringify(token),
  //   })
  //     .then((resp) => {
  //       if (resp.ok) {
  //         return resp.json();
  //       } else {
  //         return false;
  //       }
  //     })
  //     .then((user) => {
  //       setUserLoggedIn(user);
  //       history.push("/dashboard");
  //     });
  // }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coxhoe Trail Run</title>
      </Helmet>
      <div className={modalOn ? 'App modalOn' : 'App'}>
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
