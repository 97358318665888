import styled from 'styled-components';
import { Heading1, Heading3, Paragraph } from '../components/Text';

const PageContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 80svw;
  margin: auto;
  padding: 1rem 0rem;
  gap: 1rem;
  overflow: auto;
`;

export const PrivacyPolicyPage = () => (
  <PageContainer>
    <Heading1>Privacy Policy</Heading1>
    <Paragraph>
      Active Life @Coxhoe is committed to protecting the privacy and security of
      your personal information. This Privacy Policy outlines the types of
      personal information we collect, how we use and protect that information,
      and your rights regarding your personal data.
    </Paragraph>
    <Heading3>1. Information We Collect</Heading3>
    <Paragraph>
      We may collect the following types of personal information: Name Contact
      information, including email address, phone number, and postal address
      Demographic information such as postcode, preferences, and interests Other
      information relevant to customer surveys and/or offers
    </Paragraph>
    <Heading3>2. How We Collect Information</Heading3>
    <Paragraph>
      We may collect personal information when you: Visit our website Register
      an account with us Place an order for products or services Subscribe to
      our newsletter or marketing communications Participate in surveys or
      promotions
    </Paragraph>
    <Heading3>3. Use of Information</Heading3>
    <Paragraph>
      Providing products and services to you Communicating with you about your
      account or transactions Improving our products and services Marketing and
      promotional purposes, if you have opted in to receive such communications
    </Paragraph>
    <Heading3>4. Legal Basis for Processing</Heading3>
    <Paragraph>
      We process personal information based on the following legal grounds: Your
      consent Performance of a contract with you Compliance with legal
      obligations Our legitimate interests
    </Paragraph>
    <Heading3>5. Data Sharing</Heading3>
    <Paragraph>
      We may share your personal information with: Third-party service providers
      who assist us in operating our website or conducting our business Legal or
      regulatory authorities, as required by law
    </Paragraph>
    <Heading3>6. Data Retention</Heading3>
    <Paragraph>
      We retain personal information for as long as necessary to fulfill the
      purposes outlined in this Privacy Policy, unless a longer retention period
      is required or permitted by law.
    </Paragraph>
    <Heading3>7. Data Security</Heading3>
    <Paragraph>
      We are committed to ensuring that your personal information is secure. We
      have implemented appropriate technical and organizational measures to
      protect against unauthorized access, disclosure, alteration, or
      destruction of your personal information.
    </Paragraph>
    <Heading3>8. Your Rights</Heading3>
    <Paragraph>
      You have the right to: Access and obtain a copy of your personal
      information Rectify any inaccurate or incomplete personal information
      Request the erasure of your personal information under certain
      circumstances Restrict or object to the processing of your personal
      information under certain circumstances
    </Paragraph>
    <Heading3>9. Marketing Preferences</Heading3>
    <Paragraph>
      If you have opted in to receive marketing communications from us, you may
      opt out at any time by emailing info@activelifecoxhoe.co.uk.
    </Paragraph>
    <Heading3>10. Cookies and Tracking Technologies</Heading3>
    <Paragraph>
      We use cookies and similar tracking technologies to analyze trends,
      administer the website, track users movements around the website, and
      gather demographic information about our user base. You can control
      cookies through your browser settings.
    </Paragraph>
    <Heading3>11. Changes to this Privacy Policy</Heading3>
    <Paragraph>
      We may update this Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </Paragraph>
    <Heading3>12. Contact Us</Heading3>
    <Paragraph>
      If you have any questions or concerns about this Privacy Policy or our
      privacy practices, you may contact us at email info@activelifecoxhoe.co.uk
      or 01913771789. This Privacy Policy was last updated on 01/05/2024.
    </Paragraph>
  </PageContainer>
);
