import styled from 'styled-components';
import { rem } from 'polished';
import { motion } from 'framer-motion';
import { Button } from './Button';

const HeroVideoSection = styled.section`
  position: relative;
  display: grid;
  height: calc(100vh - ${rem('70px')});
  align-items: center;

  video {
    height: calc(100vh - ${rem('100px')});
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: -2;
  }

  .videoFilter {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    position: absolute;
    z-index: -1;
  }

  .overlayText {
    display: grid;
    grid-gap: ${rem('10px')};

    h1,
    h2,
    h3 {
      color: white;
    }
    h1 {
      font-size: 3.5rem;
    }
    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2.5rem;
      color: orange;
    }
  }
`;

const HeroVideo = () => (
  <HeroVideoSection>
    <div className="videoFilter" />
    <video
      src="https://coxhoetrailrunimages-videos.s3.eu-west-2.amazonaws.com/Coxhoe+Trail+Run+Final.mp4"
      loop
      muted
      autoPlay
    />
    <div className="overlayText container80">
      <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        Coxhoe Trail Run
      </motion.h1>

      <motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        Sunday 13th October 2024
      </motion.h2>
      <motion.h3 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        Registrations Are Open
      </motion.h3>

      <Button
        href="#registraion"
        as={motion.a}
        animate={{ x: 0 }}
        initial={{ x: '-100vw' }}
      >
        Register Today
      </Button>
    </div>
  </HeroVideoSection>
);

export default HeroVideo;
