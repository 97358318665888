/* eslint-disable object-curly-newline */
import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'styled-components';

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { store } from './store';
import { theme } from './components/Theme';
import App from './App';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL,
  cache: new InMemoryCache(),
});

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
);
