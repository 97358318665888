import styled from 'styled-components';

const Bar = styled.span`
  opacity: ${(props) => (props.active ? '0' : '100')};
`;
const HamburgerContainer = styled.div`
  position: relative;
  display: block;
  width: 35px;
  cursor: pointer;
  background: none;
  appearance: none;
  outline: none;
  border: none;

  ${Bar}, &::before,
  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 5px;
    background-color: ${(props) => props.theme.colors.yellow};
    margin: 6px 0px;
    transition: 0.4s;
  }

  ${(props) =>
    props.active &&
    `&::before {
    transform: rotate(-45deg) translate(-10px, 6px);
  }`}

  ${(props) =>
    props.active &&
    `
  &::after {
    transform: rotate(45deg) translate(-10px, -6px);
  }`}
`;

export const Hamburger = ({ isActive = false }) => (
  <HamburgerContainer active={isActive}>
    <Bar active={isActive} />
  </HamburgerContainer>
);
