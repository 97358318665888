import styled from 'styled-components';

export const Heading1 = styled.h1`
  color: ${({ theme }) => theme.colors.blue};
`;
export const Heading2 = styled.h2`
  color: ${({ theme }) => theme.colors.blue};
`;
export const Heading3 = styled.h3`
  color: ${({ theme }) => theme.colors.blue};
`;
export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.blue};
`;
export const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
  text-decoration: none;
`;
