import {
  PAYMENT_ITEM_SELECTED,
  PAYMENT_FORM,
  SET_USER_ID,
  SET_REFERAL_LINK,
} from './actionTypes';

export const setPaymentItemSelected = (payload) => ({
  type: PAYMENT_ITEM_SELECTED,
  payload,
});

export const setPaymentForm = (payload) => ({
  type: PAYMENT_FORM,
  payload,
});

export const setUserId = (payload) => ({
  type: SET_USER_ID,
  payload,
});

export const setReferalLink = (payload) => ({
  type: SET_REFERAL_LINK,
  payload,
});
