import styled from 'styled-components';
import { rem } from 'polished';
import { Anchor } from './Text';

const FooterContainer = styled.footer`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: ${rem('75px')};
  background: white;
  color: ${(props) => props.theme.colors.blue};
`;

const Footer = () => (
  <FooterContainer>
    <Anchor href="/privacy">Privacy</Anchor>
    <h3>&#169; Active Life Coxhoe</h3>
  </FooterContainer>
);
export default Footer;
