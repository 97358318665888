import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimension = () => {
  const [windowDimension, setWindowDimension] = useState(getWindowDimensions());
  useEffect(() => {
    window.addEventListener(
      'resize',
      setWindowDimension(getWindowDimensions()),
    );
  }, []);
  return windowDimension;
};
