import { gql } from '@apollo/client';
import { CORE_ORDER_FIELDS } from './fragments';

export const CREATENEWORDER = gql`
  ${CORE_ORDER_FIELDS}
  mutation CreateOrder($input: NewOrder) {
    CreateOrder(input: $input) {
      success
      order {
        ...CoreOrderFields
      }
    }
  }
`;

export const UPDATEORDERSTATUS = gql`
  mutation Mutation($input: UpdateOrder) {
    UpdateOrderStatus(input: $input) {
      result {
        status
        customer {
          _id
          firstname
          lastname
          email
          dob
          address
          postcode
          telephonenumber
          gender
          ageonraceday
          signature
          referal
          shirtsize
          orderstatus
          clubname
          clubmember
          registrationnumber
        }
      }
    }
  }
`;

export const CREATE_NEW_USER = gql`
  mutation Mutation($input: NewUser) {
    CreateNewUser(input: $input) {
      success
      userId
    }
  }
`;
