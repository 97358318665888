/* eslint-disable object-curly-newline */
import {
  PAYMENT_ITEM_SELECTED,
  PAYMENT_FORM,
  SET_USER_ID,
  SET_REFERAL_LINK,
} from '../actions/actionTypes';

const initialState = {
  item: '',
  form: {},
  status: '',
  userid: '',
  referallink: 'google',
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_ITEM_SELECTED:
      return {
        ...state,
        item: action.payload,
      };
    case PAYMENT_FORM:
      return {
        ...state,
        ...action.payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        userid: action.payload,
      };
    case SET_REFERAL_LINK:
      return {
        ...state,
        referallink: action.payload,
      };
    default:
      return state;
  }
};
