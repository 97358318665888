import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import AboutUs from '../components/AboutUs';
import HeroVideo from '../components/HeroVideo';
import OfferComponent from '../components/Offer';
import RegistrationComponent from '../components/Registration';
import SponsorsComponent from '../components/Sponsors';
import Modal from '../components/modal';
import { setReferalLink } from '../actions';

const HomepageContainer = styled.main`
  display: grid;
  grid-template-rows: 1fr 750px 400px 600px 300px;

  @media screen and (max-width: 700px) {
    grid-template-rows: 1fr 1fr 1fr 1100px 300px;
  }
`;

function Homepage({
  modalOn,
  setModal,
  setUserLoggedIn,
  urlTrackingEndpoints,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    urlTrackingEndpoints.forEach((url) => {
      if (location.pathname === url) {
        dispatch(setReferalLink(url));
      }
    });
  }, [location, urlTrackingEndpoints, dispatch]);
  return (
    <>
      <HomepageContainer>
        <HeroVideo />
        <AboutUs />
        <OfferComponent />
        <RegistrationComponent />
        <SponsorsComponent />
      </HomepageContainer>
      {modalOn && (
        <Modal
          modalOn={modalOn}
          setModal={setModal}
          setUserLoggedIn={setUserLoggedIn}
        />
      )}
    </>
  );
}

export default Homepage;
