/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable object-curly-newline */
import { useMutation } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useParams } from 'react-router';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// import { ImageListItem, ImageList } from '@mui/material';
import axios from 'axios';
import styled from 'styled-components';
import { UPDATEORDERSTATUS } from '../mutations';
import SponsorsComponent from '../components/Sponsors';
import { AboutUsContainer } from '../components/AboutUs';

const PaymentConfContainer = styled.section`
  display: flex;
  height: calc(100% - 100px);
  flex-direction: column;
  gap: 40px;
  ,
`;

const OnTheDayContainer = styled(AboutUsContainer)`
  height: 350px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  place-self: center;
`;

const PaymentAlert = styled(Alert)`
  margin: 20px auto;
  height: 100px;
  width: 80%;
`;

const PaymentConfirmation = () => {
  // const itemData = [
  //   {
  //     img: 'https://coxhoetrailrunimages-videos.s3.eu-west-2.amazonaws.com/brand.jpeg',
  //     title: 'Its all in the brand with Leeanne',
  //     website: 'https://consultant.thebodyshop.com/en-gb/myshop/leeannehanson',
  //   },
  //   {
  //     img: 'https://coxhoetrailrunimages-videos.s3.eu-west-2.amazonaws.com/logo_activelife.png',
  //     title: 'Active Life Coxhoe',
  //     website: 'https://www.activelifecoxhoe.co.uk',
  //   },
  // ];
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [updateOrderStatus, { loading, data }] = useMutation(UPDATEORDERSTATUS);

  const { orderId } = useParams();
  const stripe = useStripe();
  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (!orderId) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({ paymentIntent }) => {
        if (paymentIntent.status === 'succeeded' && orderId) {
          setPaymentSuccess(true);
          await updateOrderStatus({
            variables: { input: { orderId, status: 'Completed' } },
          });
        }
      });
  }, [stripe, orderId, updateOrderStatus]);

  useEffect(() => {
    const updateData = async () => {
      const result = data?.UpdateOrderStatus?.result[0];

      if (result && !loading) {
        if (result.status === 'Completed') {
          await axios.post(
            `${process.env.REACT_APP_EXPRESS}/confirmedpayment`,
            result.customer,
          );
        }
      }
    };
    updateData();
  }, [data, loading]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <PaymentConfContainer>
      {paymentSuccess && (
        <PaymentAlert severity="success">
          <AlertTitle>Success</AlertTitle>
          Your Registration To Coxhoe Trail Run 2024 Has Been Successful! <br />
          <strong>
            Please Check Your Emails For Your Payment Confirmation
          </strong>
        </PaymentAlert>
      )}

      <OnTheDayContainer>
        <h2>On the day</h2>
        <p>
          8:30am: Registrations open and collection of t-shirts and numbers.
        </p>
        <p>
          9:30am: Registrations close and collection of t-shirts and numbers.
        </p>
        <p>
          Note: It takes around 10 - 15 minutes walk from the sport center to
          the start.
        </p>
        <p>9:50am: Run Debrief Starts.</p>
        <p>10:00am: Run Starts.</p>
        <p>
          Where do I park? We have around 30 car parking spaces at the leisure
          centre first come first served. Please bare in mind locals when
          parking on streets.
        </p>
      </OnTheDayContainer>
      {/* <GoogleMaps /> */}

      <SponsorsComponent />

      {/* <ImageList
        sx={{ width: 750, height: 181, placeSelf: 'center' }}
        cols={2}
        rowHeight={164}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              onClick={() => window.open(item.website, '_blank')}
            />
          </ImageListItem>
        ))}
      </ImageList> */}
    </PaymentConfContainer>
  );
};

export default PaymentConfirmation;
