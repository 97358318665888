import { gql } from '@apollo/client';

export const CORE_ORDER_FIELDS = gql`
  fragment CoreOrderFields on Order {
    _id
    item
    status
    total
  }
`;

export const CORE_BASIC_USER_FIELDS = gql`
  fragment CoreBasicUserFields on User {
    customerID
    firstname
    lastname
    email
    dob
    address
    postcode
    telephonenumber
  }
`;
