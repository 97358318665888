// Mobile Dimension Type
export const MOBILE_SET_USING_MOBILE = 'MOBILE_SET_USING_MOBILE';

// Payment Dimension Type
export const PAYMENT_ITEM_SELECTED = 'PAYMENT_ITEM_SELECTED';
export const PAYMENT_FORM = 'PAYMENT_FORM';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_REFERAL_LINK = 'SET_REFERAL_LINK';

// Order type
export const ADD_ORDER = 'ADD_ORDER ';
