import { configureStore } from '@reduxjs/toolkit';
import { mobileReducer, orderReducer, paymentReducer } from './reducers';

export const store = configureStore({
  devTools: false,
  reducer: {
    mobile: mobileReducer,
    payment: paymentReducer,
    order: orderReducer,
  },
});
